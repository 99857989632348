import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, LoginGuard } from './core/services'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Set route
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    canActivate:[LoginGuard]
  },
  // {
  //   // path: 'maxim-apps',
  //   path: 'maxim-apps',
  //   loadComponent: () => import('./core/index').then(com => com.ApplicationSelectorComponent),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'dashboard',
    loadComponent: () => import('./core/index').then(com => com.MenuViewComponent),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'dashboard',
  //   loadComponent: () => import('./core/index').then(com => com.MenuViewComponent),
  //   // canActivate: [AuthGuard],

  // },
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
